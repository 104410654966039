import React from 'react'
import { useTheme } from '@material-ui/core/styles'
import { Grid, Box, useMediaQuery } from '@material-ui/core'
import Layout from '../components/Layout'
import AboutPageQuery from '../queries/AboutPageQuery'
import OptImages from '../components/OptImages'
import styled from 'styled-components'
import ContactSection from '../components/ContactSection'
import { Fade } from '../components/Transitions'
import Visible from '../components/Visible'
import MapSvg from '../../resources/images/svg/inline/worldmap_brandie.svg'

const StyledBox = styled(Box)`
  ::-webkit-scrollbar {
    display: none;
  }
`

const StyledMapBox = styled(Box)`
  text-align: center;
  overflow: hidden;
  padding-left: 0;
  padding-right: 0;
  background: white;
  position: relative;
  padding: 50px 0 40px;
  margin-bottom: 0;
  z-index: 1;

  svg {
    width: 100%;
    transform: scale(1.9) translateX(-16%) !important;
    height: auto;
    padding: 90px 0;
  }

  @media (min-width: 600px) {
    svg {
      transform: scale(1) translateX(0%) !important;
      padding: 3% 6%;
    }
  }
`
const WhatWeDoImg = styled(OptImages.WhatWeDo)`
  width: 100%;
  height: 50vh;
  z-index: -1;
  ${({ theme }) => theme.breakpoints.up('md')} {
    height: 100vh;
    width: 50vw;
    position: fixed !important;
  }
`

export default () => {
  const theme = useTheme()
  const smUp = useMediaQuery(theme.breakpoints.up('sm'))
  return (
    <AboutPageQuery
      render={(content) => (
        <Layout>
          <Box>
            <Grid
              container
              spacing={0}
              justify='center'
              direction='row-reverse'
            >
              <Grid item xs={12} md={6} container alignItems='center'>
                <Grid item>
                  <StyledBox
                    color='text.primary'
                    m={smUp ? 10 : 3}
                    my={0}
                    py={smUp ? 7 : 12}
                  >
                    <div
                      style={{
                        height: '120px',
                        width: '120px',
                        background: 'white',
                        borderRadius: '100px',
                        display: 'flex',
                        justifyContent: 'left',
                        alignItems: 'left',
                        marginBottom: '10px'
                      }}
                    >
                      <OptImages.EmojiPeace style={{ width: '90px' }} />
                    </div>
                    <h2>What we do</h2>
                    <p>
                      Brandie’s Crowd Marketing technology was researched and
                      developed in Sweden by behavioral scientists and
                      engineers. The goal has been to carefully convert
                      customers into brand ambassadors, at scale, and be able to
                      give them meaningful rewards for their loyalty. With
                      Machine Learning and Artificial Intelligence, we make it
                      possible for brands to implement successful & smart UGC
                      campaigns.
                      <br />
                      <br />
                      Brandie has analyzed over half a million user-generated
                      photos across the globe to perfect customer advocacy and
                      drive UGC on social media. This massive amount of
                      knowledge on customer habits and photo-sharing behavior
                      has given us a proven track record of guiding our clients
                      to success. Brandie's unique technology has been tailored
                      around scalable AI solutions to enable social media
                      marketing campaigns at scale.
                      <br />
                      <br />
                      Led by serial entrepreneurs and former executives from
                      companies like IKEA, Oriflame, Gionee, and H&M. Brandie is
                      built on the bedrock of key insights from years of
                      marketing expertise in both traditional as well as digital
                      marketing channels.
                    </p>
                  </StyledBox>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <WhatWeDoImg />
              </Grid>
            </Grid>
          </Box>

          <StyledMapBox>
            <Visible
              render={(isVisible, ref) => (
                <React.Fragment>
                  <Fade in={isVisible}>
                    <h1 ref={ref}>Our offices</h1>
                  </Fade>
                  <Fade in={isVisible}>
                    <MapSvg />
                  </Fade>
                </React.Fragment>
              )}
            />
          </StyledMapBox>

          <ContactSection />
        </Layout>
      )}
    />
  )
}
